<template>
  <div class="row">
    <div>
      <b-modal ref="save-modal" hide-footer title="Add Contact">
        <div class="row">
          <div class="col-md-12">
            <b-form-group id="input-group-2" label="Email:" label-for="input-2">
              <b-form-input
                id="email"
                @keyup="isEmailValid"
                placeholder="Enter Email"
                v-model="email"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-form-group id="input-group-2" label="Phone:" label-for="input-2">
              <b-form-input
                v-model="phone"
                type="number"
                placeholder="Enter Phone"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div style="float: right;">
          <a class="btn btn-info" @click="appendRow">Save</a>
        </div>
      </b-modal>
    </div>
    <div class="col-12">
      <div class="card">
        <div></div>
        <div class="col-12 mt-2">
          <div></div>
          <div class="card-header cardHeader-bg" style="padding: 14px;">
            <h4 class="card-title mb-0">{{ headerTitle }}</h4>
          </div>
        </div>
        <div class="card-body">
          <div>
            <div
              v-if="alert"
              role="alert"
              aria-live="polite"
              aria-atomic="true"
              class="alert alert-danger"
            >
              {{ alertText }}
            </div>
            <b-form v-on:submit.prevent="submitForm" v-if="show">
              <b-form-group
                id="input-group-2"
                label="Description:"
                label-for="input-2"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="description"
                  placeholder="Enter Description here..."
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>

              <b-button ref="save" type="submit" variant="primary"
                >Save</b-button
              >
              <!-- <a
                @click="openModal()"
                style="margin-left: 12px; margin-top:2px"
                class="btn btn-primary btn btn-primary mb-2"
              >
                Add to Contact
              </a> -->
            </b-form>
          </div>
          <br /><br />
          <template>
            <ContactTable
              v-if="showImagesViewTable"
              :deliveryAddress="contactArray"
            />
          </template>
        </div>
      </div>
      <br />
    </div>
  </div>
</template>
<script>
// import { appendRow, deleteRow } from "@/Helper/helpers.js";
import ContactTable from "@/view/pages/Settings/contactUs/ContactTable";
import axios from "axios";
export default {
  components: {
    ContactTable,
  },
  data() {
    return {
      contactArray: [],
      headerTitle: "Add Contact",
      description: "",
      loans: [],
      email: "",
      phone: "",
      isShowModel: false,
      alertText: "",
      alert: false,
      show: true,
      changeClasss: false,
      showImagesViewTable: false,
      apiResponceError: false,
      emailValidation: false,
    };
  },

  methods: {
    openModal() {
      this.$refs["save-modal"].show();
    },
    isEmailValid: function isEmailValid() {
      this.apiResponceError = false;
      var regex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
      var responce = regex.test(this.email);
      if (responce) {
        this.alert = false;
      } else {
        this.alertText = "Please enter valid email address";
        this.changeClasss = false;
        this.alert = true;
        this.emailValidation = true;
      }
      return responce;
    },

    appendRow() {
      let data = {
        email: this.email,
        phone: this.phone,
      };
      this.contactArray.push(data);
      this.$refs["save-modal"].hide();
      this.email = "";
      this.phone = "";
      this.showImagesViewTable = true;
    },
    submitForm() {
      try {
        const form = {
          description: this.description,
          contact_infomation: JSON.stringify(this.contactArray),
        };
        const headers = {
          Authorization: "Bearer " + localStorage.getItem("id_token"),
        };
        axios
          .post(this.$path + "/contact/addcontact", form, {
            headers,
          })
          .then((response) => {
            if (response.data.code == 200) {
              this.$router.push({ name: "contactus" });
            }
            if (response.data.code == 422) {
              this.alert = true;
              this.alertText = response.data.message;
            }
            if (response.data.code == 501) {
              this.$router.push({ name: "login" });
            }
          })
          .catch(({ response }) => {
            if (response) {
              alert("Technical Issue");
            }
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    addLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },
  },
};
</script>
<style scoped>
#add-loan-btn {
  padding: 8px 25px 8px 25px;
}
.multiselect__single {
  font-size: 12px;
}
.multiselect * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 13px;
}

.multiselect__option--highlight:after {
  background-color: #428bca !important;
}

.alert {
  padding: 0.3rem 0.5rem;
}
</style>
